import React from 'react';

import { Layout, SEO } from 'components';
import { Policy } from 'containers';

const PolicyPage = () => (
  <Layout noBackground>
    <SEO title='Policy' />
    <Policy />
  </Layout>
);

export default PolicyPage;
